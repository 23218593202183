<template>
  <v-layout id="outer" justify-center align-center>
    <v-col class="">
      <v-row class="justify-center">
        <h1 class="font-weight-light">
          Ooops! The page you requested for does not exist
          <v-icon>mdi-emoticon-confused-outline</v-icon>
        </h1>
      </v-row>
      <v-row class="justify-center">
        <v-btn color="primary" class="mt-2" :to="{ name: 'Dashboard' }">
          back to home
        </v-btn>
      </v-row>
    </v-col>
  </v-layout>
</template>

<script>
export default {};
</script>

<style scoped>
#outer {
  height: 100%;
}
</style>
